<template>
    <div class="main">
        <div class="banner">
            <div class="banner-overlay"></div>
             <h1>Réservation<br /> d'une démo en live</h1>
        </div>
        <!-- <section class="demo-title">
            <div class="row">
                <h2 class="gradiant-text">TITLE 2</h2>
                <p> 
                    Abusus enim multitudine hominum, quam tranquillis in rebus diutius rexit, ex agrestibus habitaculis urbes construxit multis opibus firmas et viribus, quarum ad praesens pleraeque licet Graecis
                </p>
            </div>
        </section> -->
        <section class="demo-container">
            <div class="demo-content">
                <h2>VOUS VOULEZ UNE DÉMO EN LIVE ?</h2>                          
                <h3>Merci de compléter ce formulaire</h3>
                <p>L'un des membres de notre équipe vous contactera très prochainement pour planifier une démonstration live de notre solution technique !</p>
                <div class="form-component">
                    <Form></Form>  
                </div>    
            </div>      
        </section>
    </div>
</template>


<script>
import Form from './../components/Form'

export default {
    name : 'Demo',
    components : {
        Form
    }
}
</script>

<style lang="scss" scoped>

    h1 {
        text-transform:uppercase;
    }

    h2 {
        margin-bottom:40px;
    }

    .banner {
        background-image : url('./../assets/bg-about.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height:300px;
        min-height: auto;
    }

    .demo-title {
        display: flex;
        justify-content: center;
        padding-top:40px;
        padding-bottom:40px;
    }

    .demo-title .row {
        width: $boxWidth;
        max-width: 96%;
        flex-direction: column;       
    }

    .demo-container {
        padding:40px 0 80px 0;
        display: flex;
        flex-direction: column;
        align-items:center;
        background-image: url('./../assets/bg-stands.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }


    .demo-content{
        width: $boxWidth;
        max-width: 96%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    

    .demo-container h2, .demo-container h3, .demo-container p {
        color:#fff;
        text-align: center;
    }

    .demo-container h3 {
        font-size:25px;
        font-weight: 400;
    }

    .form-component {
        margin-top:40px;
        width: 100%;
        max-width: 800px;
    }

    @media screen and (max-width: 768px) {
        .row {
            max-width: 90%;
            margin:0 5%;
        }

    }
</style>